
import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';
import { UserModule } from "@/store/modules/user";
import AvatarPixel from "@/components/AvatarPixel.vue";
import { viewportToPixels } from "@/utils/tools";

interface IActiveItem {
  id: number;
  type: number;
  operationTime: number;
  amount: number;
}

@Component({
  components: { AvatarPixel },
})
export default class UserActivityPanel extends Vue {
  @Prop({
    default: ''
  })
  public currentId!: string;

  private currentPage = 1;

  private get account() {
    return UserModule.address;
  }

  private selected(item: IActiveItem) {
    return `${item.id}` === this.currentId;
  }

  public tapItem(item: IActiveItem) {
    this.$router.push("/active/" + item.id);
  }

  public convertViewPort(input: string): number {
    return viewportToPixels(input);
  }
}
