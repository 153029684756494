
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import UserActivityPanel from "@/components/UserActivityPanel.vue";
import AvatarPixel from "@/components/AvatarPixel.vue";

interface IActivity {
  id: number;
  account: string;
  type: number;
  validator: string;
  validatorName: string;
  amount: number;
  operationTime: number;
  targetName: string;
  targetMethod: string;
  blockNumber: number;
  txid: string;
}

// TODO: data need to complete

@Component({
  components: { AvatarPixel, UserActivityPanel },
  apollo: {
    activityById: {
      query: require("../../apollo/graphql/ActivityById.graphql"),
      variables() {
        return {
          id: parseInt(this.id),
        };
      },
    },
  },
})
export default class VoteHistoryDetails extends Vue {
  @Prop(String)
  public id!: string;

  public activityById: IActivity = {
    id: 0,
    account: "",
    type: 0,
    validator: "",
    validatorName: "",
    amount: 0,
    operationTime: 0,
    targetName: "",
    targetMethod: "",
    blockNumber: 0,
    txid: "",
  };
}
